<template>
  <div class="my-2">
    <div class="cursor-pointer">
      <div @click="openExportModal" class="flex available-action px-4 py-2">
        <span class="material-icons text-lg self-center">upload_file</span>
        <span class="text-sm ml-2">{{ $t('Export') }}</span>
      </div>
    </div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeExportModal"
      :active.sync="exportModal"
      :title="$t('Export Task Lists')"
      :buttons-hidden="true">
      <div class="text-center mt-1">
        <div class="mb-4">
          <p class="text-darkgray text-base mb-4">{{ $t('Select agent type!') }}</p>
          <div class="clearfix flex justify-center">
            <div v-for="(agent, index) in agentTypes" :key="index">
              <label @click="checkAgentsType(agent)" class="prompt cursor-pointer whitespace-nowrap">
                <input v-model="agentType" :class="agent.checked ? 'checked' : ''" :value="agent.value" type="radio" />
                <span class="py-2 px-4 rounded mx-1">{{ agent.name }}</span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="agentType" class="max-w-sm m-auto my-4">
          <v-select v-model="agent" :placeholder="$t('Agent Name')" label="fullName" :options="allAgents"/>
        </div>
        <p class="text-darkgray">{{ $t('Select your export range!') }}</p>
        <div class="mt-4 md:flex block gap-4 justify-center">
          <div class="flex justify-center">
            <datepicker :inline="true" name="from" :disabledDates="disabledDatesStart" v-model="startDate"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
          </div>
          <div class="flex justify-center md:mt-0 mt-4">
            <datepicker :inline="true" name="to" :disabledDates="disabledDates" v-model="endDate"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
          </div>
        </div>
        <div class="flex justify-evenly mt-3">
          <p class="text-blue-900 date-border p-2">{{startDate ? common.gettingDate(new Date(startDate).toISOString()) : $t('No Date')}}</p>
          <div class="flex" :style="$i18n.locale === 'ar' ? 'transform: rotate(180deg);' : ''">
            <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
            <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
          </div>
          <p class="text-blue-900 date-border p-2">{{endDate ? common.gettingDate(new Date(endDate).toISOString()) : $t('No Date')}}</p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeExportModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="sendExport" :class="startDate ? 'active-btn' : 'disable-btn pointer-events-none'" class="btn">{{ $t('Export') }}</button>
        </div>
      </div>
    </shipblu-prompt>
    <success-prompt :active.sync="sucessModal"
      :text="$t('We are working on your export,')"
      :optionalText="$t('We will send you a notification once it’s done.')"
      :title="$t('Success!')"
      :img="ExchangeSuccess"></success-prompt>
  </div>
</template>

<script>
import ExchangeSuccess from '../../../assets/images/pages/exchange-success.svg'
import SuccessPrompt from '../../../layouts/components/SuccessPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  data () {
    return {
      ExchangeSuccess,
      common,
      disabledDates: {
        to: new Date() // Disable all dates up to specific date
      },
      disabledDatesStart: {
        from: new Date() // Disable all dates up to specific date
      },
      startDate: '',
      endDate: '',
      exportModal: false,
      agentType: '',
      agent: '',
      allAgents: [],
      agentTypes: [
        {
          name: 'First Mile',
          value: 'MA',
          checked: false
        },
        {
          name: 'Last Mile',
          value: 'CA',
          checked: false
        }
      ],
      sucessModal: false
    }
  },
  watch: {
    'exportModal' () {
      this.disabledDatesStart = {
        from: new Date() // Disable all dates up to specific date
      }
    },
    'startDate' () {
      this.endDate = this.startDate ? new Date(this.startDate) : ''
      this.disabledDates.to = new Date(this.startDate)
      this.disabledDates.from = new Date()
    },
    'agentType' (val) {
      if (val) {
        this.agent = ''
        this.loadAgents()
      }
    }
  },
  methods: {
    checkAgentsType (agent) {
      this.agentTypes.forEach(item => {
        if (item.name === agent.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    loadAgents () {
      sendRequest(false, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/agents/${this.agentType === 'MA' ? 'merchant_agent' : 'customer_agent'}/`, 'get', null, true,
        (response) => {
          this.allAgents = response.data.results
          this.allAgents.forEach(item => (
            item.fullName = `${item.first_name} ${item.last_name}`
          ))
        }
      )
    },
    openExportModal () {
      this.$validator.resume()
      this.exportModal = true
      this.disabledDates = {
        from: new Date() // Disable all dates up to specific date
      }
    },
    closeExportModal () {
      this.exportModal = false
      this.agentType = ''
      this.startDate = ''
      this.endDate = ''
      this.agentTypes.forEach(item => {
        item.checked = false
      })
    },
    sendExport () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const url = 'api/v1/analytics/task-sheets/export/'
          sendRequest(false, false, this, `${url}?from=${new Date(this.startDate).toISOString().split('T')[0]}&to=${new Date(this.endDate).toISOString().split('T')[0]}&agent_type=${this.agentType}&agent_name=${this.agent ? this.agent.fullName : ''}`, 'get', null, true,
            () => {
              this.closeExportModal()
              this.sucessModal = true
            }
          )
        }
      })
    }
  },
  components: {
    Datepicker,
    vSelect,
    ShipbluPrompt,
    SuccessPrompt
  }
}
</script>

<style lang="scss" scoped>
.text-darkgray {
  color: #475C6F;
}
.date-border {
  border-bottom: 0.6px solid #9DADC2;
}
.btn {
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  line-height: 18px;
}
.disable-btn {
  background: #F1F3F6;
  color: #6C84A3;
}
.active-btn {
  color: #fff;
  font-weight: 500;
  background: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
}
</style>
